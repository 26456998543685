import { FC } from "react";
import styles from "./styles";
import * as yup from 'yup';
import { Form, Formik } from "formik";
import { FormProps } from "../../types/formProps";

const VALIDATION_SCHEMA = yup.object().shape({
    fname: yup.string().required('First Name required'),
    lname: yup.string().required('Last Name required'),
    email: yup.string().email().required('Email required'),
    phone: yup.number().required('Phone No required'),
    desc: yup.string().required('Description required')
})

const INITIAL_VALUES: FormProps = {
    fname: '',
    lname: '',
    email: '',
    phone: null,
    desc: ''
}

const InputForm: FC = () => {
    const handleSubmit = (values: FormProps) => {
        console.log(values, ' = Values')
    }

    return (
        <div className={styles.container}>
            <div className={styles.inner_container}>
                <Formik initialValues={INITIAL_VALUES} validationSchema={VALIDATION_SCHEMA} onSubmit={handleSubmit}>
                    {({ values, handleChange, errors, touched }) => (
                        <Form>
                            <div className={styles.inputs_wrapper}>
                                <div className="mb-[17px]">
                                    <p className={styles.label}>
                                        First Name*
                                    </p>
                                    <input
                                        name="fname"
                                        value={values.fname}
                                        onChange={handleChange}
                                        placeholder="Enter title"
                                        autoFocus={false}
                                        className={styles.input}
                                        aria-invalid={errors.fname && touched.fname ? "true" : "false"}
                                        aria-describedby={errors.fname && touched.fname ? "fname-error" : undefined}
                                    />
                                    {errors.fname && touched.fname && (
                                        <div id="fname-error"
                                            className="text-[#d32f2f] text-[0.75rem] ml-[14px] font-medium mt-[3px]">
                                            {errors.fname}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-[17px]">
                                    <p className={styles.label}>
                                        Last Name*
                                    </p>
                                    <input
                                        name="lname"
                                        value={values.lname}
                                        onChange={handleChange}
                                        placeholder="Last Name"
                                        className={styles.input}
                                        aria-invalid={errors.lname && touched.lname ? "true" : "false"}
                                        aria-describedby={errors.lname && touched.lname ? "lname-error" : undefined}
                                    />
                                    {errors.lname && touched.lname && (
                                        <div id="lname-error"
                                            className="text-[#d32f2f] text-[0.75rem] ml-[14px] font-medium mt-[3px]">
                                            {errors.lname}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-[17px]">
                                    <p className={styles.label}>
                                        Email Address*
                                    </p>
                                    <input
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email Address"
                                        className={styles.input}
                                        aria-invalid={errors.email && touched.email ? "true" : "false"}
                                        aria-describedby={errors.email && touched.email ? "email-error" : undefined}
                                    />
                                    {errors.email && touched.email && (
                                        <div id="email-error"
                                            className="text-[#d32f2f] text-[0.75rem] ml-[14px] font-medium mt-[3px]">
                                            {errors.email}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-[17px]">
                                    <p className={styles.label}>
                                        Phone Number*
                                    </p>
                                    <input
                                        name="phone"
                                        value={values.phone || ''}
                                        onChange={handleChange}
                                        placeholder="Phone Number"
                                        className={styles.input}
                                        aria-invalid={errors.phone && touched.phone ? "true" : "false"}
                                        aria-describedby={errors.phone && touched.phone ? "phone-error" : undefined}
                                    />
                                    {errors.phone && touched.phone && (
                                        <div id="phone-error"
                                            className="text-[#d32f2f] text-[0.75rem] ml-[14px] font-medium mt-[3px]">
                                            {errors.phone}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-[17px]">
                                <p className={styles.label}>
                                    Description
                                </p>
                                <textarea
                                    name="desc"
                                    value={values.desc}
                                    onChange={handleChange}
                                    placeholder="How we can help you?"
                                    className={`${styles.input} py-[18px] min-h-[178px] md:min-h-[95px]`}
                                    aria-invalid={errors.desc && touched.desc ? "true" : "false"}
                                    aria-describedby={errors.desc && touched.desc ? "desc-error" : undefined}
                                />
                                {errors.desc && touched.desc && (
                                    <div id="desc-error"
                                        className="text-[#d32f2f] text-[0.75rem] ml-[14px] font-medium mt-[3px]">
                                        {errors.desc}
                                    </div>
                                )}
                            </div>
                            <button type="submit" className={styles.button}>
                                Submit
                            </button>
                        </Form>
                    )}
                </Formik>
                <p className={styles.text}>
                    We use your details only to contact you about our products and services. You can unsubscribe anytime.
                </p>
                <p className={styles.text}>
                    Learn more about how we respect your privacy in our Privacy policy.
                </p>
            </div>
        </div>
    )
}

export default InputForm;
