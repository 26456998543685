const styles = {
    container:"flex flex-col lg:flex-row items-start justify-between md:mb-[60px] lg:mb-[78px] mt-[41px] gap-x-[170px] px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] mx-auto max-w-[1595px]",
    title:"text-[30px] md:text-[50px] lg:text-[55px] font-[700] font-[Montserrat] text-black mb-[20px] md:mb-[14px] lg:mb-[17px]",
    desc:"text-[16px] md:text-[18px] font-[400] text-black font-[Montserrat]",
    props:"w-full lg:w-1/2 flex flex-row justify-between mx-auto max-w-[1595px] items-start sm:max-w-[500px] lg:max-w-[600px] mt-[50px] lg:mt-0",
    prop_title:"font-[400] text-[18px] text-black font-[Montserrat]",
    prop_desc:"text-[18px] font-[700] font-[Montserrat] text-black",
    image:"h-fit w-full mx-auto max-w-[1370px]",
    desc_wrapper:"px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] pt-[66px] md:pt-[78px] mx-auto max-w-[1595px]",
    
}
export default styles;