import { FC } from "react";
import styles from "./styles";

const BottomSection: FC = () => {
    return (
        <div style={{ backgroundImage: 'url(/assets/images/Rectangle64.png)' }} className={styles.container}>
            <p className={styles.heading}>
                We're Leading Digital Agency For Increasing Sales or Boosting Traffic
            </p>
            <p className={styles.paragraph}>
                A team of experts, a process that works, technologies that stand out, and ideas that influence, your success and growth is guaranteed!
            </p>
            <button className={styles.button} onClick={() => { window.open('https://calendly.com/jataq-co/discovery-call', '_blank') }}>
                Services We Provide
                <img src='/assets/icons/arrow.svg' alt='arrow_icon' className={styles.button_icon} />
            </button>
        </div>
    )
}
export default BottomSection;