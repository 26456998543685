import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RouteProp {
  route: string;
}

const initialState: RouteProp = {
  route: "/"
};

export const selectedRoute = createSlice({
  name: 'selectedRoute',
  initialState,
  reducers: {
    selected_route: (state, action: PayloadAction<string>) => {
      state.route = action.payload;
    }
  }
});

export const { selected_route } = selectedRoute.actions;
export default selectedRoute.reducer;
