import { FC } from "react";
import styles from "./styles";
import Typical from 'react-typical';

const Hero: FC = () => {
    return (
        <div className={styles.container}>
            <div className="flex flex-col">
                <div className={styles.tablet_text_wrapper}>
                    <p className={styles.heading}>
                        Transforming Your
                    </p>
                    <div className={styles.tablet_wrapper}>
                        <div className={styles.idea}>
                            <Typical
                                steps={[
                                    "Idea",
                                    1000,
                                    "Vision",
                                    1000,
                                    "Product",
                                    1000,
                                ]}
                                loop={Infinity}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.mobile_text_wrapper}>
                    <div className={styles.mobile_wrapper}>
                        <div className={styles.idea}>
                            <Typical
                                steps={[
                                    "Idea",
                                    1000,
                                    "Vision",
                                    1000,
                                    "Product",
                                    1000,
                                ]}
                                loop={Infinity}
                            />
                        </div>
                    </div>
                    <p className={`${styles.heading}`}>
                        Into Reality
                    </p>
                </div>
            </div>

            <p className={styles.paragraph}>
                Step forward to become one of
                <span className={styles.blue_text}>
                    100+ successful
                </span>
                clients of Jataq and get in the front line for the most wonderful presentation.
            </p>
            <div className={styles.last_section}>
                <div className={styles.blue_wrapper}>
                    <button className={styles.button} onClick={() => {window.open('https://calendly.com/jataq-co/discovery-call', '_blank')}}>
                        Schedule a Meeting
                        <img src='/assets/icons/arrow.svg' alt='arrow_icon' className={styles.icon} />
                    </button>
                </div>
                <button className={styles.contact}>
                    Contact Expert
                </button>
            </div>
            <button className={`${styles.button} mx-auto flex md:hidden`} onClick={() => {window.open('https://calendly.com/jataq-co/discovery-call', '_blank')}}>
                Schedule a Meeting
                <img src='/assets/icons/arrow.svg' alt='arrow_icon' className={styles.icon} />
            </button>
        </div>
    )
}
export default Hero;