import { FC, StrictMode } from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import Store from "./store";

const App: FC = () => {
  return (
    <Provider store={Store}>
      <AppRoutes />
    </Provider>
  )
}
export default App; 