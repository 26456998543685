import { DescProps } from "../types/pageDesc";

export const WORK_OPTIONS: DescProps[] = [
    {
        title: 'Schedule an Initial Consultation',
        desc: "Contact us to discuss your project. We'll cover team structure, objectives, budget, schedule, and the skills you need."
    },
    {
        title: 'Discuss Solution and Team Structure',
        desc: "We'll collaborate with you to finalize project specifications, agree on the engagement model, and select the right team for your project."
    },
    {
        title: 'Get Started and Track Performance',
        desc: "Once milestones are set, we'll begin work, monitoring progress, updating you regularly, and adapting to your needs."
    },
]