import { journeyProps } from "../types/journeyServices";

export const JOURNEY_SERVICES: journeyProps[] = [
    {
        icon: '/assets/icons/user-bold.svg',
        label: 'Customer Centricity',
        desc: 'Our clients are our priority. We listen to their needs, understand their challenges, and work tirelessly to deliver IT solutions that meet and exceed their expectations. Their success is our success.'
    },
    {
        icon: '/assets/icons/quality_assurance_icon.svg',
        label: 'Quality Assurance',
        desc: 'We have a relentless commitment to quality. We set high standards and rigorously test and monitor our IT services to ensure they meet those standards. Quality is non-negotiable.'
    },
    {
        icon: '/assets/icons/client_focus_icon.svg',
        label: 'Client Focus',
        desc: 'Our clients are the center of our IT universe. We listen to their needs, provide tailored solutions, and stand by them every step of the way. Their success is our success, excellence, integrity.'
    },
    {
        icon: '/assets/icons/integrity_icon.svg',
        label: 'Integrity Always',
        desc: 'Integrity is the foundation of trust. We operate with complete transparency, ensuring the highest ethical standards in all our IT operations. Our clients rely on us for honesty and fairness.'
    },
]