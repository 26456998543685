import { FC } from "react";
import ServicesBar from "../../sections/services-bar";
import styles from "./styles";

const CarePatron: FC = () => {
    return (
        <>
            <div className={styles.container}>
                <div className="w-full lg:w-1/2">
                    <p className={styles.title}>
                        Case Study Of CarePatron
                    </p>
                    <p className={styles.desc}>
                        Proin nec lectus ac magna sagittis consectetur in vel odio. Quisque in arcu ut ipsum
                    </p>
                </div>
                <div className={styles.props}>
                    <div>
                        <div className="mb-[28px]">
                            <p className={styles.prop_title}>
                                Clients:
                            </p>
                            <p className={styles.prop_desc}>
                                John Martis
                            </p>
                        </div>
                        <div className="mb-[28px]">
                            <p className={styles.prop_title}>
                                Time Frame:
                            </p>
                            <p className={styles.prop_desc}>
                                John Martis
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className="mb-[28px]">
                            <p className={styles.prop_title}>
                                Category:
                            </p>
                            <p className={styles.prop_desc}>
                                Web Development
                            </p>
                        </div>
                        <div className="mb-[28px]">
                            <p className={styles.prop_title}>
                                Website Link:
                            </p>
                            <p className={styles.prop_desc}>
                                N/A
                            </p>
                        </div>
                    </div>

                </div>
            </div>

            <img src="/assets/images/Rectangle95.png" className={styles.image} />

            <div className={styles.desc_wrapper}>
                <p className="text-[28px] md:text-[38px] font-[700] font-[Montserrat] text-[#030303] mb-[12px]">
                    Projects Overview
                </p>
                <div className="flex flex-col lg:flex-row items-start gap-x-[36px] gap-y-[28px] md:gap-y-[50px] lg:gap-y-0">
                    <p className="text-[16px] md:text-[18px] font-[400] font-[Montserrat] text-black">
                        As we reflect on this achievement, we look forward to continuing our journey of digital innovation, creating transformative solutions, and shaping the future of digital landscape. Celebrating a major milestone in digital services is a significant occasion.
                    </p>
                    <p className="text-[16px] md:text-[18px] font-[400] font-[Montserrat] text-black">
                        As we reflect on this achievement, we look forward to continuing our journey of digital innovation, creating transformative solutions, and shaping the future of digital landscape. Celebrating a major milestone in digital services is a significant occasion.
                    </p>
                </div>
            </div>

            <div className="px-[18px] sm:px-[60px] mx-auto max-w-[1595px] md:px-[82px] lg:px-[95px] xl:px-[115px] pt-[46px] md:pt-[60px]">
                <div className="w-full flex flex-col xl:flex-row items-start justify-between gap-x-[5%] pb-[60px]">
                    <div className="w-full xl:max-w-[55%]">
                        <p className="text-[28px] md:text-[38px] font-[700] font-[Montserrat] text-[#030303] mb-[12px]">
                            Challenges & Constraints
                        </p>
                        <p className="text-[16px] md:text-[18px] font-[400] font-[Montserrat] text-black">
                            When creating a detailed case study, it's important to provide comprehensive information that communicates the story of the project, including the challenges faced, the solutions implemented, and the achieved results.
                        </p>
                        <ul className="list-disc pl-[32px] pt-[5px]">
                            <li className="text-[16px] md:text-[18px] font-[400] font-[Montserrat] text-black">
                                The digital agency landscape is highly competitive, requireing agencies to differentiate themselves.
                            </li>
                            <li className="text-[16px] md:text-[18px] font-[400] font-[Montserrat] text-black">
                                With a focus on technology, digital agencies have teams skilled in web development.
                            </li>
                        </ul>
                        <img src="/assets/images/Rectangle80-crop.png" className="w-[555px] mx-auto block xl:hidden max-h-[577px] my-[46px] md:mt-[70px]" />

                        <p className="text-[38px] font-[700] font-[Montserrat] text-[#030303] mb-[12px] lg:mt-[60px]">
                            Projects Solution
                        </p>
                        <p className="text-[18px] font-[400] font-[Montserrat] text-black">
                            When creating a detailed case study, it's important to provide comprehensive information that communicates.<br /> It's important to provide comprehensive information that communicates the story of the project, including the challenges faced, the solutions implemented, and the achieved results.
                        </p>
                    </div>
                    <img src="/assets/images/Rectangle80-crop.png" className="hidden xl:block w-fit max-h-[577px]" />
                </div>

                <div className="bg-[#006fff] w-full md:h-[269px] lg:h-[443px] rounded-[10px] flex flex-col md:flex-row items-center p-[22px] gap-x-[35px] lg:gap-x-[40px] xl:gap-x-[67px]">
                    <img src="/assets/images/Rectangle96.png" className="w-full max-w-[399px] md:w-[242px] lg:w-[399px] md:h-[242px] lg:h-[399px]" />
                    <div>
                        <div className="flex flex-row items-center mt-[33px] md:mt-0">
                            <img src="/assets/icons/full-star.svg" className="h-[23px] lg:h-[37px] w-[23px] lg:w-[37px]" />
                            <img src="/assets/icons/full-star.svg" className="h-[23px] lg:h-[37px] w-[23px] lg:w-[37px]" />
                            <img src="/assets/icons/full-star.svg" className="h-[23px] lg:h-[37px] w-[23px] lg:w-[37px]" />
                            <img src="/assets/icons/full-star.svg" className="h-[23px] lg:h-[37px] w-[23px] lg:w-[37px]" />
                            <img src="/assets/icons/half-star.svg" className="h-[23px] lg:h-[37px] w-[23px] lg:w-[37px]" />
                        </div>
                        <p className="text-[18px] lg:text-[26px] xl:text-[32px] font-[700] text-white font-[Montserrat] mt-[15px]">
                            Jataq Technologies Exceeded Our Expectations With Their Cloud Computing Solutions. Highly Recommeded!
                        </p>
                        <p className="text-[18px] font-[700] text-white mt-[20px] lg:mt-[40px] font-[Montserrat]">
                            John Martis
                        </p>
                        <p className="text-white text-[18px] font-[400] font-[Montserrat]">
                            CEO CarePatron
                        </p>
                    </div>
                </div>

                <div className="my-[30px] md:my-[50px] lg:my-[80px] xl:my-[110px]">
                    <p className="text-[28px] md:text-[38px] mb-[35px] font-[700] text-center text-[#030303] font-[Montserrat]">
                        Related Case Studies
                    </p>
                    <div className="grid grid-cols-1 gap-y-[38px] md:grid-cols-3 gap-x-[45px] overflow-hidden">
                        <div className="aspect-square overflow-hidden rounded-[20px] w-full max-w-[400px] mx-auto hover:border-[3px] border-[#006fff]">
                            <img src="/assets/images/Rectangle85-crop.png" className="w-full h-full object-cover " />
                        </div>
                        <div className="aspect-square overflow-hidden rounded-[20px] w-full max-w-[400px] mx-auto hover:border-[3px] border-[#006fff]">
                            <img src="/assets/images/Rectangle86-crop.png" className="w-full h-full object-cover" />
                        </div>
                        <div className="aspect-square overflow-hidden rounded-[20px] w-full max-w-[400px] mx-auto hover:border-[3px] border-[#006fff]">
                            <img src="/assets/images/Rectangle85-crop.png" className="w-full h-full object-cover" />
                        </div>
                    </div>
                </div>

            </div>
            <ServicesBar />
        </>
    )
}
export default CarePatron;