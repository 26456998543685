import { FC, useEffect } from "react";
import { QUICK_LINKS } from "../../constants/quickLinks";
import { SOCIAL_LINKS } from "../../constants/socialLinks";
import { SocialProps } from "../../types/socialLinks";
import styles from "./styles";
import { QuickLinkProps } from "../../types/quickLinks";
import { useLocation, useNavigate } from "react-router-dom";
import { SERVICES } from "../../constants/services";
import { selected_route } from "../../store/reducers/selectedRoute";
import { useDispatch } from "react-redux";

const Footer: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const currentPath = location.pathname;
        dispatch(selected_route(currentPath))
    }, [location])
    return (
        <div className={styles.container}>
            <div className=" max-w-[1380px] mx-auto">
                <div className={styles.top_section}>
                    <div className={styles.logo_wrapper}>
                        <img src="/assets/logo/Logo.svg" alt='logo' className={styles.logo_image} />
                        <div className='flex flex-col'>
                            <p className={styles.jataq}>
                                JATAQ
                            </p>
                            <p className={styles.technologies}>
                                TECHNOLOGIES
                            </p>
                        </div>
                    </div>
                    <div className={styles.socials_wrapper}>
                        {SOCIAL_LINKS?.map((link: SocialProps, index: number) => (
                            <div key={index} className={styles.social_link}
                                onClick={() => { window.open(link.url, '_blank') }}>
                                <img src={link?.icon} className={styles.social_icon} />
                                <p className={styles.social_label}>
                                    {link?.label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.content_wrapper}>
                    <div className={styles.first_section}>
                        <p className={styles.idea_text}>
                            Have an idea?
                        </p>
                        <p className={styles.tell_us_text}>
                            Tell us about it
                        </p>
                        <div className={styles.contacts_wrapper}>
                            <div className={styles.contact_wrapper}
                                onClick={() => window.location.href = `mailto:jataq@gmail.com`}>
                                <img src='/assets/icons/email.svg' alt='email_icon' className={styles.contact_icon} />
                                <p className={styles.contact_text}>
                                    info@jataq.co
                                </p>
                            </div>
                            <div className={styles.contact_wrapper} onClick={() => window.location.href = `tel:+923417697732`}>
                                <img src='/assets/icons/phone-bold.svg' alt='email_icon' className={styles.contact_icon} />
                                <p className={styles.contact_text}>
                                    +92 3135066453
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.second_section}>
                        <div>
                            <p className={styles.quick_links}>
                                Quick Links
                            </p>
                            <div className={styles.links_wrapper}>
                                {QUICK_LINKS.map((link: QuickLinkProps, index: number) => (
                                    <p
                                        key={index}
                                        className={styles.link}
                                        style={{ cursor: 'pointer', zIndex: 10 }}
                                        onClick={() => {
                                            navigate(link.url, { replace: true });
                                            setTimeout(() => {
                                            window.scrollTo(0, 0);
                                        }, 50);
                                        }}
                                    >
                                        {link.label}
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className={styles.services_wrapper}>
                            <div>
                                <p className={styles.quick_links}>
                                    Services
                                </p>
                                <div className={styles.links_wrapper}>
                                    {SERVICES.map((link: QuickLinkProps, index: number) => (
                                        <p
                                            key={index}
                                            className={styles.link}
                                            style={{ cursor: 'pointer', zIndex: 10 }}
                                            onClick={() => {
                                                navigate(link.url);
                                                setTimeout(() => {                                             
                                                window.scrollTo(0, 0);
                                            }, 50);
                                            }}
                                        >
                                            {link.label}
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className={styles.copyright}>
                    Copyright © 2024, Jataq Technologies
                </p>
            </div>
        </div>
    )
}
export default Footer;