import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

export const toggleSidebarSlice = createSlice({
  name: 'toggleSidebar',
  initialState,
  reducers: {
    toggle_sidebar: (state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const { toggle_sidebar } = toggleSidebarSlice.actions;
export default toggleSidebarSlice.reducer;
