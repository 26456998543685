import { FC, useState } from 'react';
import { HEADER_TABS } from '../../constants/headerTabs';
import { HeaderProps } from '../../types/headerTabs';
import styles from './styles';
import { useNavigate } from 'react-router-dom';
import { ServiceProps } from '../../types/serviceOptions';
import { SERVICE_OPTIONS_BLUE } from '../../constants/serviceOptionsBlue';
import { useDispatch, useSelector } from 'react-redux';
import { toggle_sidebar } from '../../store/reducers/toggleSidebar';
import Logo from '../logo';

const Header: FC = () => {
    const [services, setServices] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedTab = useSelector((state: any) => state?.selectedRoute);
    const currentTab = selectedTab.route;

    const ServicesPanel = () => {
        return (
            <div className={`${styles.panel} ${services ? 'block' : 'hidden'}`}
                onMouseEnter={() => setServices(true)}
                onMouseLeave={() => setServices(false)}>
                {SERVICE_OPTIONS_BLUE?.map((service: ServiceProps, index: number) => (
                    <div key={index}
                        className={styles.service_tab}
                        onClick={() => {
                            navigate(service.route);
                            setTimeout(() => {
                                window.scrollTo(0, 0);
                            }, 50);
                            setServices(false);
                        }}>
                        <img src={service?.icon} className='h-[27px] w-[27px]' alt="Service Icon" />
                        <p className="text-[18px] font-[400]">
                            {service.label}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <Logo />
            <div className={styles.tabs_wrapper}>
                {HEADER_TABS.map((tab: HeaderProps, index: number) => (
                    <button key={index}
                        className={`${styles.tab}  ${tab.route === currentTab ? 'font-[700]' : 'font-[400]'}`}
                        onClick={() => { tab.route !== '/services' && navigate(tab.route) }}
                        onMouseEnter={() => tab.label === 'Services' && setServices(true)}
                        onMouseLeave={() => setServices(false)}>
                        {tab.label}
                    </button>
                ))}
                <ServicesPanel />
            </div>
            <div className={styles.button_wrapper}>
                <button className={styles.button}
                    onClick={() => { window.open('https://calendly.com/jataq-co/discovery-call', '_blank') }}>
                    GET A QUOTE
                </button>
                <img src='/assets/icons/menu_icon.svg' className={styles.menu_icon}
                    onClick={() => dispatch(toggle_sidebar(true))} />
            </div>
        </div>
    )
}
export default Header;