const styles = {
    container: "flex flex-col items-center px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] max-w-[1600px] mx-auto",
    label_wrapper: "w-full flex flex-row items-center justify-center mb-[30px] md:mb-[20px] mt-[100px] h-fit",
    count_wrapper: "grid w-fit md:w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pr-4 md:border-b-[1px]",
    count: "text-[38px] font-[700] border-b-[1px]",
    borders_wrapper: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-fit md:w-full h-[43px]",
    border: "border-l-[1px] ml-[20px]",
    arrows_wrapper: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-fit md:w-full",
    arrow_icon: "h-[16px] w-[16px] ml-[13px] -mt-[1px]",
    paragraphs_wrapper: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full mb-[52px] lg:mb-[152px]",
    paragraph_title: "text-[21px] lg:text-[22px] xl:text-[24px] font-[700] font-[Montserrat] text-[#252525]",
    paragraph: "text-[18px] text-black mt-[2px] sm:mt-[4px] md:mt-[10px] mr-8 lg:mr-[60px] lg:mt-[15px] font-[400] font-[Montserrat]"
}
export default styles;