const styles = {
    container: " bg-no-repeat bg-cover bg-center flex flex-col lg:flex-row items-start justify-between py-[30px] sm:py-[70px] md:py-[95px] bg-[#0a0b0f] px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] w-full",
    section_title:"flex items-center justify-center text-white border-[1px] text-[14px] font-[300] font-[Montserrat] border-white absolute z-30 w-[119px] h-[31px] mt-2 rounded-full bg-black",
    first_section: "flex flex-col items-start lg:w-1/2",
    heading: "text-white text-[26px] sm:text-[28px] md:text-[30px] lg:text-[35px] max-w-[500px] xl:text-[38px] font-[700] font-[Montserrat] mt-[60px]",
    text: "w-full justify-center flex-wrap text-[18px] text-white mt-[26px] font-[400] font-[Montserrat] max-w-[85%]",
    yellow_text: "pl-[6px] pr-[4px] text-[#ffe000] font-[400] font-[Montserrat] text-[18px]",
    button: "flex flex-row items-center justify-center gap-x-[6px] lg:gap-x-[11px] h-[32px] sm:h-[40px] md:h-[48px] lg:h-[51px] xl:h-[53px] w-fit px-2 xl:w-[218px] bg-[#1b7eff] rounded-[10px] mt-[28px] md:mt-[48px] text-[18px] text-white font-[500] font-[Montserrat]",
    second_section: "flex flex-col mt-4 lg:mt-0 max-w-[640px] w-full lg:w-1/2",
    service_wrapper: "flex flex-row w-full items-center mt-[20px] md:mt-[34px] lg:mt-[25px] w-full xl:mt-[35px] cursor-pointer",
    icon_wrapper: "flex items-center justify-center min-h-[60px] sm:min-h-[70px] md:min-h-[88px] lg:min-h-[70px] xl:min-h-[88px] min-w-[60px] sm:min-w-[70px] md:min-w-[88px] lg:min-w-[70px] xl:min-w-[88px] rounded-[10px]",
    icon: "h-[40px] md:h-[55px] lg:w-[40px] xl:w-[55px] w-[40px] md:w-[55px] lg:w-[45px] xl:w-[55px]",
    text_wrapper: "flex flex-row items-center mx-[20px] md:mx-[41px] lg:mx-[25px] xl:mx-[41px] justify-between h-[88px] w-full border- border-b-[1px] border-[#4e4e4e] pb-[15px]",
    service_text: "text-white text-[18px] font-[400] font-[Montserrat]",
    arrow_icon: "h-[12px] sm:h-[13px] md:h-[14px] lg:h-[15px] xl:h-[16.25px]"
}
export default styles;