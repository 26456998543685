const styles={
    container:"flex flex-col lg:flex-row justify-between pt-[7px] md:py-[5px] lg:pt-[20px] md:mb-[80px] lg:mb-[120px] px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] mx-auto max-w-[1500px] 2xl:max-w-[1600px] gap-x-[5%]",
    section_title_wrapper:"w-fit block lg:hidden mt-20",
    section_title:"flex items-center justify-center text-black border-[1px] text-[14px] font-[300] font-[Montserrat] border-black absolute z-30 w-[119px] h-[31px] mt-2 rounded-full bg-white",
    first_section:"flex flex-col items-start w-fit xl:max-w-[45%] lg:pt-[135px]",
    image_wrapper:"flex flex-col items-center absolute",
    first_image:"z-0 w-[280px] sm:w-[340px] md:w-[380px] lg:w-[400px] xl:w-[480px]",
    second_image:"mt-[19px] absolute z-10 w-[35px] sm:w-[38px] md:w-[45px] lg:w-[53px] xl:w-[60px]",
    text_section:"flex flex-row items-end w-full z-30 mt-[158px] sm:mt-[116px] md:mt-[205px] lg:mt-[225px] xl:mt-[197px]",
    text_wrapper:"flex flex-col justify-center bg-[#006fff] rounded-[17px] h-[110px] md:h-[160px] md:min-w-[230px] mb-[5px] lg:mb-[9px] mt-[10px] px-[15px] sm:px-[18px] md:px-[18px]",
    complete_icon:"w-[25px] h-[25px] sm:w-[30px] sm:h-[30px] md:w-[46px] md:h-[46px]",
    first_text:"text-[15px] sm:text-[18px] md:text-[24px] w-full font-[700] font-[Montserrat] text-white mt-[6px]",
    second_text:"text-[12px] sm:text-[14px] md:text-[18px] font-[400] font-[Montserrat] text-white",
    last_image:"w-[150px] sm:w-[230px] md:w-[220px] xl:w-[300px]",
    second_section:"flex flex-col w-full lg:w-1/2 max-w-[640px] pt-2",
    heading:"text-[28px] md:text-[38px] lg:text-[38px] xl:text-[38px] font-[700] text-black font-[Montserrat] pt-[20px] md:pt-[30px] lg:pt-[60px]",
    paragraph:"text-[18px] font-[400] font-[Montserrat] text-black mt-[15px]",
    bullets:"flex flex-col lg:max-w-[500px]",
    bullet:"flex flex-row items-center mt-[13px] gap-x-[10px]",
    bullet_icon:"h-[25px] w-[30px] sm:h-[33px] sm:w-[40px] xl:h-[50px] xl:w-[59px]",
    bullet_text:"text-[17px] xl:text-[18px] font-[400] font-[Montserrat]",
    buttons_wrapper:"flex flex-row items-center gap-x-[15px] sm:gap-x-[19px] lg:gap-x-[23px] h-[53px] mt-[15px] md:mt-[30px] lg:mt-[40px]",
    first_button:"bg-[#006fff] outline-none text-white rounded-[10px] md:rounded-[20px] w-[190px] sm:w-[220px] lg:min-w-[240px] xl:min-w-[268px] h-[40px] sm:h-[45px] lg:h-[53px] text-[17px] lg:text-[18px] font-[500] lg:font-[500]",
    second_button:"cursor-pointer underline text-[16px] md:text-[17px] xl:text-[18px] font-[400] font-[Montserrat]"
}
export default styles;