import React from 'react';
import { SERVICES_BAR } from '../../constants/servicesBarOptions';
import styles from './styles';

const ServicesBar: React.FC = () => {
    const containerStyle: React.CSSProperties = {
        display: 'flex',
        backgroundColor: '#006fff',
        overflow: 'hidden',
        height: '109px',
        whiteSpace: 'nowrap',
        width: '100%',
        alignItems: 'center',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    };

    const marqueeStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        animation: 'marquee 8s linear infinite',
    };

    const keyframesStyle = `
    @keyframes marquee {
      0% { transform: translateX(0); }
      100% { transform: translateX(-12%); }
    }
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
  `;

    return (
        <div style={containerStyle} className="hide-scrollbar">
            <style>{keyframesStyle}</style>
            <div style={marqueeStyle}>
                {[...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR, ...SERVICES_BAR].map((item, index) => (
                    <React.Fragment key={index}>
                        <img src="/assets/icons/asterisk.svg" alt="asterisk_icon" className={styles.asterisk} />
                        <p className={styles.text}>{item}</p>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default ServicesBar;
