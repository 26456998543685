import { FC, useState } from "react";
import { SERVICE_OPTIONS } from "../../constants/serviceOptions";
import styles from "./styles";
import styles2 from '../bottom-section/styles';
import { useNavigate } from "react-router-dom";
import { ServiceProps } from "../../types/serviceOptions";

const Services: FC = () => {
    const [hovered, setHovered] = useState<number | null>(null);
    const navigate = useNavigate();
    return (
        <>
            <img src="/assets/icons/border.svg" alt="border_line" className="w-full" />
            <div style={{ backgroundImage: 'url(/assets/logo/bg-image.svg)' }} className={styles.container}>
                <div className="flex flex-col lg:flex-row items-start justify-between mx-auto max-w-[1350px]">
                    <div className={styles.first_section}>
                        <img src='/assets/icons/Rectangle58.svg' alt='button_background' className="absolute z-0" />
                        <p className={styles.section_title}>
                            Our Services
                        </p>
                        <p className={styles.heading}>
                            Services That JATAQ Provides
                        </p>
                        <p className={styles.text}>
                            Step forward to become one of
                            <span className={styles.yellow_text}>
                                1000+ successful
                            </span>
                            clients of Jataq and get in the front line for the most wonderful presentation.
                        </p>
                        <button className={styles.button} onClick={() => { navigate('/contact'); window.scrollTo(0, 0) }}>
                            Contact Us
                            <img src='/assets/icons/arrow.svg' alt='arrow_icon' className={styles2.button_icon} />
                        </button>
                    </div>

                    <div className={styles.second_section}>
                        {SERVICE_OPTIONS?.map((service: ServiceProps, index: number) => (
                            <div
                                key={index}
                                className={styles.service_wrapper}
                                onMouseEnter={() => setHovered(index)}
                                onMouseLeave={() => setHovered(null)}
                                onClick={() => {
                                    navigate(service.route);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <div
                                    className={`${styles.icon_wrapper}`}
                                    style={{
                                        backgroundColor: hovered === index ? '#006fff' : '#272727',
                                        padding: '10px',
                                    }}
                                >
                                    <img src={service?.icon} alt={service?.icon} style={{ width: '50px', height: '50px' }} />
                                </div>
                                <div className={styles.text_wrapper}>
                                    <p className={styles.service_text}>{service?.label}</p>
                                    <img
                                        src={hovered === index ? '/assets/icons/blue_arrow.svg' : '/assets/icons/arrow.svg'}
                                        alt="arrow_icon"
                                        style={{ width: '20px', height: '20px' }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Services;