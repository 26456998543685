import { configureStore, combineReducers } from "@reduxjs/toolkit";
import toggleSidebarReducer from "./reducers/toggleSidebar";
import selectedRouteReducer from "./reducers/selectedRoute";

const rootReducer = combineReducers({
  toggleSidebar: toggleSidebarReducer,
  selectedRoute: selectedRouteReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
