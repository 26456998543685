const styles = {
    container: "flex flex-col pt-[45px] sm:pt-[55px] md:pt-[105px] lg:pt-[120px] xl:pt-[135px] pb-[33px] sm:pb-[80px] md:pb-[95px] lg:pb-[110px] xl:pb-[135px]",
    first_section: "flex flex-row items-start justify-end",
    tablet_text_wrapper: "flex flex-row items-center justify-center md:justify-end w-full",
    mobile_text_wrapper: "flex flex-row items-center justify-start md:justify-center",
    heading: "text-[30px] md:text-[50px] lg:text-[62px] xl:text-[70px] font-[700] font-[Montserrat]",
    tablet_wrapper: "bg-[#fdcc1d] flex-row items-center px-[25px] md:ml-[15px] lg:ml-[20px] md:h-[80px] lg:h-[95px] xl:h-[104px]     w-[30%] hidden md:flex  rounded-l-full",
    mobile_wrapper:" bg-[#fdcc1d] flex-row items-center justify-end px-[18px] sm:px-[25px] mr-[10px] sm:mr-[20px] h-[68px] w-[40%] min-w-[160px] flex md:hidden rounded-r-full",
    idea: "text-[30px] md:text-[50px] font-[700] font-[Montserrat]",
    paragraph: "w-full px-[10px] flex justify-center flex-wrap max-w-[683px] text-[16px] md:text-[18px] text-center text-[#252525] mt-3 mx-auto font-[300] font-[Montserrat]",
    blue_text: "pl-[6px] pr-[4px] text-[#006fff] font-[700] font-[Montserrat] text-[18px]",
    text:"text-[18px] font-[Montserrat] text-[#252525]",
    last_section: "hidden md:flex flex-row items-center gap-x-[12px] sm:gap-x-[16px] md:gap-x-[22px] lg:gap-x-[25px] xl:gap-x-[28px] mt-[25px]",
    blue_wrapper: "flex flex-row items-center w-[50%] text-right bg-[#006fff] md:h-[80px] lg:h-[95px] xl:h-[104px] rounded-tr-full rounded-br-full justify-end pr-[10px] sm:pr-[12px] md:pr-[15px] lg:pr-[19px] xl:pr-[22px]",
    button: "flex flex-row items-center justify-center gap-x-[6px] lg:gap-x-[11px] px-[21px] min-h-[53px]  xl:h-[53px] w-fit min-w-[214px] px-2 lg:px-3 xl:w-[257px] bg-[#1b7eff] rounded-full text-[18px] text-white font-[500] font-[Montserrat] mt-[30px] md:mt-0",
    icon: "h-[12px] sm:h-[13px] md:h-[14px] lg:h-[15px] xl:h-[16.25px]",
    contact: "text-[18px] font-[300] text-[#252525] font-[Montserrat] cursor-pointer underline"
}
export default styles;