const styles = {
    container:"w-full mt-[38px] md:mtx-[88px] lg:mt-[137px] px-[30px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] mx-auto max-w-[1620px]",
    title:"text-[25px] sm:text-[30px] md:text-[35px] lg:text-[38px] font-[700] font-[Montserrat] max-w-[835px] mx-auto text-center",
    desc:"text-[16px] sm:text-[17px] lg:text-[18px] font-[400] max-w-[667px] mx-auto font-[Montserrat] text-center mt-[17px]",
    phases_wrapper:"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-[38px] sm:mt-[45px] md:mt-[60px] pb-[84px]",
    first_phase:"md:border-r-[1px] mb-7 md:mb-0 border-b-[1px] pt-0 md:pr-[20px] xl:pr-[38px]",
    second_phase:"lg:border-r-[1px] mb-7 md:mb-0 border-b-[1px] pt-0 md:pl-[20px] lg:px-[20px] xl:px-[38px]",
    third_phase:"md:border-r-[1px] mb-7 md:mb-0 lg:border-r-0 pt-0 border-b-[1px] md:pr-[20px] lg:pr-0 lg:pl-[20px] xl:pl-[38px]",
    fourth_phase:"border-r-0 lg:border-r-[1px] mb-7 md:mb-0 border-b-[1px] lg:border-b-0 md:pt-[32px] md:pl-[20px] lg:pl-0 lg:pr-[20px] xl:pr-[38px]",
    fifth_phase:"md:border-r-[1px] md:pt-[32px] md:pr-[20px] border-b-[1px] md:border-b-0 mb-7 md:mb-0 lg:px-[20px] xl:px-[38px]",
    sixth_phase:"md:pt-[32px] md:pl-[20px] lg:pl-[20px] xl:pl-[38px] mb-7 md:mb-0 ",
    phase:"border-[#d2d2d2] pb-[15px] md:pb-[29px]",
    index:"text-[25px] sm:text-[30px] md:text-[35px] lg:text-[38px] font-[700]",
    phase_title:"text-[22px] lg:text-[24px] font-[700] text-[#252525] mt-[7px]",
    phase_desc:"text-[16px] sm:text-[17px] lg:text-[18px] mb-3 md:mb-0 font-[400] font-[Montserrat] text-black mt-[18px]",
}
export default styles;