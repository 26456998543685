import { FC, useEffect, useState } from "react";
import styles2 from "../header/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggle_sidebar } from "../../store/reducers/toggleSidebar";
import { SERVICE_OPTIONS_BLUE } from "../../constants/serviceOptionsBlue";
import { ServiceProps } from "../../types/serviceOptions";
import styles from "./styles";
import Logo from "../logo";

const Sidebar: FC = () => {
    const [services, setServices] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const value = useSelector((state: any) => state.toggleSidebar);

    const handleResize = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth > 1024) {
            dispatch(toggle_sidebar(false));
            setServices(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const ServicesPanel = () => {
        return (
            <div className={`${styles.panel} ${services === true ? 'block' : 'hidden'} py-[10px] px-[20px] shadow-2xl`}
                onMouseEnter={() => setServices(true)} onMouseLeave={() => setServices(false)}>
                {SERVICE_OPTIONS_BLUE?.map((service: ServiceProps, index: number) => (
                    <div key={index} className={styles.services_tab}
                        onClick={() => {
                            navigate(service.route);
                            setServices(false);
                            dispatch(toggle_sidebar(false));
                            setTimeout(() => {
                                window.scrollTo(0, 0);
                            }, 100);
                        }}
                    >
                        <img src={service?.icon} className='h-[27px] w-[27px]' />
                        <p className="text-[18px] font-[400]">
                            {service.label}
                        </p>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={`${styles.container} ${value === true && 'shadow-2xl'} ${value ? 'slide-in-right' : 'slide-out-right'}`}>
            <div className={styles.header}>
                <div className="mt-[39px]">
                    <Logo />
                </div>
                <img src="/assets/icons/close_icon.svg" className={styles.close_icon}
                    onClick={() => { dispatch(toggle_sidebar(false)); setServices(false) }} />
            </div>
            <p className={`${styles.tab} border-[#c4c4c4] border-b-[1px] `} onClick={() => { navigate('/'); dispatch(toggle_sidebar(false)) }}>
                Home
            </p>
            <div className="flex flex-row items-center justify-between cursor-pointer border-[#c4c4c4] border-b-[1px]" onClick={() => setServices(!services)}>
                <p className={styles.tab} >
                    Services
                </p>
                <img src="/assets/icons/arrow-down.svg" className={`h-[7.67px] w-[13.31px] mt-[20px] mr-[5px] ${services === true &&
                    'rotate-180'}   `} />
                <ServicesPanel />
            </div>

            <p className={`${styles.tab} border-[#c4c4c4] border-b-[1px] `} onClick={() => { navigate('/about'); dispatch(toggle_sidebar(false)) }}>
                About Us
            </p>

            {/* <p className={styles.tab} onClick={() => { navigate('/case-studies'); dispatch(toggle_sidebar(false)) }}>
                Case Studies
            </p> */}

            <p className={styles.tab} onClick={() => { navigate('/contact'); dispatch(toggle_sidebar(false)) }}>
                Contact Us
            </p>
        </div>
    )
}
export default Sidebar;