import { FC, useState } from "react"
import SectionTitle from "../../components/section-title";
import ServicesBar from "../../sections/services-bar";
import { useNavigate } from "react-router-dom";

const CaseStudies: FC = () => {
    const [hovered1, setHovered1] = useState<boolean>(false);
    const [hovered2, setHovered2] = useState<boolean>(false);
    const [hovered3, setHovered3] = useState<boolean>(false);
    const [hovered4, setHovered4] = useState<boolean>(false);
    const navigate = useNavigate();
    return (
        <div className="mt-[63px]">
            <SectionTitle title="Case Studies" desc="Explore our success stories, showcasing innovative solutions and impactful results across various industries." />
            <div style={{ backgroundImage: 'url(/assets/images/Rectangle69.png)' }} className=" px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] pt-[75px] pb-[80px] lg:pb-[153px] bg-no-repeat bg-cover bg-center ">
                <div className="flex flex-col lg:flex-row justify-evenly mb-[60px] gap-x-[55px] h-fit max-w-[1535px] mx-auto">
                    <div className="w-full lg:w-7/12 xl:w-[675px] mx-auto cursor-pointer mb-[59px] lg:mb-0"
                        onClick={() => { navigate('/care-patron'); window.scrollTo(0, 0) }} onMouseEnter={() => setHovered1(true)} onMouseLeave={() => setHovered1(false)}>
                        <img src="/assets/images/Rectangle78-crop.png" className="xl:h-[459px] h-fit w-full rounded-[20px] hover:border-[3px] border-[#006fff]" />
                        <div className="pt-[19px]">
                            <div className="flex flex-row items-start justify-between">
                                <p className="text-[28px] md:text-[34px] lg:text-[36px] xl:text-[38px] font-[700] text-[#030303] font-[Montserrat]">
                                    Web Performance
                                </p>
                                <img src={`${hovered1 === true ? "/assets/icons/forward_icon.svg" : "/assets/icons/forward_icon_black.svg"}`} className="hidden md:block md:h-[46px] lg:h-[48px] md:w-[46px] lg:w-[48px] mt-3" />
                            </div>
                            <p className="text-[18px] font-[400] text-[#a0a0a0] font-[Montserrat]">
                                Website Optimization
                            </p>
                        </div>
                    </div>

                    <div className="w-full lg:w-5/12 xl:w-[459px] mx-auto flex flex-col justify-between cursor-pointer"
                        onClick={() => { navigate('/care-patron'); window.scrollTo(0, 0) }} onMouseEnter={() => setHovered2(true)} onMouseLeave={() => setHovered2(false)}>
                        <img src="/assets/images/Rectangle79-crop.jpg" className="xl:h-[459px] h-fit rounded-[20px] hover:border-[3px] border-[#006fff]" />
                        <div className="pt-[19px]">
                            <div className="flex flex-row items-start justify-between">
                                <p className="text-[28px] md:text-[34px] lg:text-[36px] xl:text-[38px] font-[700] text-[#030303] font-[Montserrat]">
                                    Isabel Mercado
                                </p>
                                <img src={`${hovered2 === true ? "/assets/icons/forward_icon.svg" : "/assets/icons/forward_icon_black.svg"}`} className="hidden md:block md:h-[46px] lg:h-[48px] md:w-[46px] lg:w-[48px] mt-3" />
                            </div>
                            <p className="text-[18px] font-[400] text-[#a0a0a0] font-[Montserrat]">
                                Web Development
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row justify-evenly lg:mb-[60px] gap-x-[55px] h-fit  max-w-[1535px] mx-auto">
                    <div className="w-full lg:w-5/12 xl:w-[459px] mx-auto cursor-pointer mb-[53px] lg:mb-0"
                        onClick={() => { navigate('/care-patron'); window.scrollTo(0, 0) }} onMouseEnter={() => setHovered3(true)} onMouseLeave={() => setHovered3(false)}>
                        <img src="/assets/images/Rectangle81.png" className="xl:h-[459px] h-fit w-full rounded-[20px] hover:border-[3px] border-[#006fff]" />
                        <div className="pt-[19px]">
                            <div className="flex flex-row items-start justify-between">
                                <p className="text-[28px] md:text-[34px] lg:text-[36px] xl:text-[38px] font-[700] text-[#030303] font-[Montserrat]">
                                    Web Performance
                                </p>
                                <img src={`${hovered3 === true ? "/assets/icons/forward_icon.svg" : "/assets/icons/forward_icon_black.svg"}`} className="hidden md:block md:h-[46px] lg:h-[48px] md:w-[46px] lg:w-[48px] mt-3" />
                            </div>
                            <p className="text-[18px] font-[400] text-[#a0a0a0] font-[Montserrat]">
                                Website Optimization
                            </p>
                        </div>
                    </div>

                    <div className="w-full lg:w-7/12 xl:w-[675px] mx-auto cursor-pointer mb-[59px] lg:mb-0"
                        onClick={() => { navigate('/care-patron'); window.scrollTo(0, 0) }} onMouseEnter={() => setHovered4(true)} onMouseLeave={() => setHovered4(false)}>
                        <img src="/assets/images/Rectangle82.png" className="xl:h-[459px] h-fit w-full rounded-[20px] hover:border-[3px] border-[#006fff]" />
                        <div className="pt-[19px]">
                            <div className="flex flex-row items-start justify-between">
                                <p className="text-[28px] md:text-[34px] lg:text-[36px] xl:text-[38px] font-[700] text-[#030303] font-[Montserrat]">
                                    CarePatron
                                </p>
                                <img src={`${hovered4 === true ? "/assets/icons/forward_icon.svg" : "/assets/icons/forward_icon_black.svg"}`} className="hidden md:block md:h-[46px] lg:h-[48px] md:w-[46px] lg:w-[48px] mt-3" />
                            </div>
                            <p className="text-[18px] font-[400] text-[#a0a0a0] font-[Montserrat]">
                                Web Development
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <ServicesBar />
        </div>
    )
}
export default CaseStudies;