import { FC } from "react";
import Footer from "../footer";
import Sidebar from "../sidebar";
import Header from "../header";
import { LayoutProps } from "../../types/layout";

const Layout: FC<LayoutProps> = ({ children }) => {
    return (
        <div className="w-full">
            <Sidebar />
            <Header />
            {children}
            <Footer />
        </div>
    );
}

export default Layout;
