import { FC, useState } from "react";
import FiguresBar from "../../components/figures-bar.tsx";
import { FeatureProps } from "../../types/features.js";
import { BenefitsProps } from "../../types/benefitsProps.js";
import styles from "./styles";

const Benefits: FC<BenefitsProps> = (props) => {
    const [hovered, setHovered] = useState<number | null>(null);
    return (
        <div style={{ backgroundImage: 'url(/assets/images/Rectangle69.png)' }} className={styles.container}>
            <div className="max-w-[1380px] mx-auto">
                <div className={styles.inner_container}>
                    <div className={styles.first_section}>
                        <p className={styles.title}>
                            {props.title}
                        </p>
                        <p className={styles.desc}>
                            {props.desc}
                        </p>
                        <button className={styles.button} onClick={() => {window.open('https://calendly.com/jataq-co/discovery-call', '_blank')}}>
                            Schedule a Meeting
                            <img src='/assets/icons/arrow.svg' alt='arrow_icon' className={styles.button_icon} />
                        </button>
                    </div>
                    <div className={styles.second_section}>
                        {props.features?.map((feature: FeatureProps, index: number) => (
                            <div key={index} className={`${styles.feature} ${index === hovered ? 'bg-[#006fff]' : 'bg-white'}`}
                                onMouseEnter={() => setHovered(index)} onMouseLeave={() => setHovered(null)}>
                                <div className={`bg-[#fff] ${hovered !== 0 && 'bg-white'} rounded-[4px] px-[2px] h-[35px] md:h-[65px] min-w-[35px] md:w-[65px] flex flex-row items-center justify-center`}>
                                    <img src={feature?.icon} className={`h-full md:h-[60px]`} />
                                </div>
                                <p className={`text-[18px] font-[500] font-[Montserrat] ${index === hovered ? 'text-white' : 'text-black'}`}>
                                    {feature?.label}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <FiguresBar />
            </div>
        </div>
    )
}
export default Benefits;