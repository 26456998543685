import { FC } from "react";
import InputForm from "../../sections/input-form";
import { SocialProps } from "../../types/socialLinks";
import { SOCIAL_LINKS_BLACK } from "../../constants/socialLinksBlack";
import ServicesBar from "../../sections/services-bar";

const Contact: FC = () => {
    return (
        <>
            <div className="flex flex-col items-start justify-between lg:flex-row mb-[70px] px-[18px] mt-[40px] md:mt-[60px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] gap-x-[55px] mx-auto max-w-[1600px] gap-y-[62px]">
                <div className="w-full lg:w-1/2">
                    <p className="text-[30px] md:text-[55px] font-[700] font-[Montserrat] max-w-[585px] text-black">
                        We'd Love To Help
                    </p>
                    <p className="text-[18px] font-[400] font-[Montserrat] text-black max-w-[700px]">
                        Enabled a startup to launch a successful mobile app by providing end-to-end development, leading to a high downloads and recognition.
                    </p>
                    <div className="flex flex-row items-start gap-y-1 sm:gap-y-0 sm:items-center w-fit gap-x-[15px] sm:gap-x-[35px] md:gap-x-[48px] mt-[33px]">
                        {SOCIAL_LINKS_BLACK?.map((link: SocialProps, index: number) => (
                            <div key={index} className="flex flex-row gap-x-[2px] cursor-pointer" onClick={()=>window.open(link.url, '_blank')}>
                                <img src={link?.icon} className="h-[24px] w-[24px]" />
                                <p className="text-black text-[18px] font-[400]">
                                    {link?.label}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="flex flex-row items-center gap-x-[18px] md:gap-x-[35px] mt-[30px]">
                        <div className="h-[45px] w-[45px] bg-[#006fff] rounded-full flex items-center justify-center">
                            <img src='/assets/icons/mail_white.svg' className="h-[27px] w-[27px]" />
                        </div>
                        <p className="text-[18px] font-[400] font-[Montserrat]">
                            contact@jataq.com
                        </p>
                    </div>
                    <div className="flex flex-row items-center gap-x-[18px] md:gap-x-[35px] mt-[30px]">
                        <div className="h-[45px] w-[45px] bg-[#006fff] rounded-full flex items-center justify-center">
                            <img src='/assets/icons/phone_white.svg' className="h-[27px] w-[27px]" />
                        </div>
                        <p className="text-[18px] font-[400] font-[Montserrat]">
                            +92 3312099944
                        </p>
                    </div>
                </div>
                <div className="-mt-[55px] md:-mt-[40px] w-full">
                    <InputForm />
                </div>
            </div>
            <ServicesBar />
        </>
    )
}
export default Contact;