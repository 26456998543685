import { FC } from "react";
import { DescProps } from "../../types/pageDesc";
import styles from "./styles";
import { WORK_OPTIONS } from "../../constants/workOptions";
import SectionTitle from "../../components/section-title";

const WorkMethod: FC = () => {
    return (
        <div className={styles.container}>
            <SectionTitle title="We work for better growing result step by step" desc="Crafting compelling digital experiences that captivate audiences and drive meaningful connections" />
            <div className={styles.paragraphs_wrapper}>
                {WORK_OPTIONS?.map((item: DescProps, index: number) => (
                    <div key={index} className="flex flex-col">
                        <p className={`${styles.count} ${index === 0 ? 'text-[#006fff] border-[#006fff]' : 'text-[#d7d7d7] border-[#d7d7d7]'}`}>
                            0{index + 1}
                        </p>
                        <div className={`${styles.border} ${index === 0 ? 'border-[#006fff]' : 'border-[#d7d7d7]'}`} />
                        <div className="flex flex-col">
                            <div className={`w-[1px] h-[30px] ml-[20px] ${index === 0 ? 'bg-[#006fff]' : 'bg-[#d7d7d7]'} `} />
                            <img src="/assets/icons/arrow-head.svg" alt="arrow_head" className={styles.arrow_icon} />
                        </div>
                        <div>
                            <p className={styles.paragraph_title}>
                                {item?.title}
                            </p>
                            <p className={styles.paragraph}>
                                {item?.desc}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default WorkMethod;