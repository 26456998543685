const styles = {
    container: "bg-[#0a0b0f] px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] pb-[25px] md:pt-[50px] lg:pt-[75px] xl:pt-[86px] md:pb-[35px] lg:pb-[48px]",
    top_section: "flex flex-col md:flex-row items-start pt-10 md:pt-0 pb-6 md:pb-[20px] lg:pb-[40px] xl:pb-[50px] justify-evenly md:justify-between",
    logo_wrapper: "flex flex-row items-center w-fit gap-x-[8px] mb-6 lg:mb-0",
    logo_image: "w-[28px] sm:w-[30px] md:w-[32px] lg:w-[34px] xl:w-[36px] h-[30px] sm:h-[32px] md:h-[34px] lg:h-[36px] xl:h-[38px]",
    jataq: "font-[Montserrat] font-[700] text-[16px] sm:text-[18px] md:text-[20px] lg:text-[22px] xl:text-[24px] text-[#fff]",
    technologies: "font-[Montserrat] text-[8px] sm:text-[8.5px] md:text-[9px] lg:text-[9.5px] xl:text-[10px] -mt-1 font-[300] text-[#fff]",
    socials_wrapper: "flex flex-row items-start gap-y-1 sm:gap-y-0 sm:items-center w-fit gap-x-[35px]",
    social_link: "flex flex-row items-center flex-wrap gap-x-1 cursor-pointer",
    social_icon: "h-[20px] md:h-[22px] lg:h-[24px] w-[20px] md:w-[22px] lg:w-[24px]",
    social_label: "text-white text-[16px] md:text-[17px] lg:text-[18px] font-[400] font-[Montserrat]",
    content_wrapper: "flex flex-col lg:flex-row border-b-[1px] border-t-[1px] border-[#353535]",
    first_section: "flex flex-col pt-[20px] justify-end w-full lg:w-[50%] border-b-[1px] lg:border-b-0 lg:border-r-[1px] border-[#353535] pb-[26px]",
    second_section: "w-full lg:w-1/2 flex flex-row items-start justify-start gap-x-[40px] md:gap-x-[100px] lg:justify-evenly py-[28px]",
    idea_text: "text-[26px] sm:text-[28px] md:text-[30px] lg:text-[35px] xl:text-[38px] font-[700] text-white font-[Montserrat]",
    tell_us_text: "text-[26px] sm:text-[28px] md:text-[30px] lg:text-[35px] xl:text-[38px] font-[700] text-white font-[Montserrat]",
    contacts_wrapper: "flex gap-y-[25px] flex-row gap-x-[13px] mt-[30px] lg:mt-[38px]",
    contact_wrapper: "flex flex-row gap-x-[10px] cursor-pointer",
    contact_icon: "h-[20px] md:h-[22px] lg:h-[24px] w-[20px] md:w-[22px] lg:w-[24px]",
    contact_text: "text-[16px] md:text-[17px] lg:text-[18px] font-[400] text-white",
    services_wrapper:"w-full lg:w-fit flex flex-row items-start justify-start",
    quick_links: "text-white text-[17px] lg:text-[18px] font-[700] font-[Montserrat] whitespace-nowrap",
    links_wrapper: "grid grid-cols-1 lg:gap-x-[40px] xl:gap-x-[140px] mt-[29px] gap-y-[17px]",
    link: "text-white cursor-pointer text-[16px] font-[400] font-[Montserrat] whitespace-nowrap",
    copyright: "text-[16px] md:text-[17px] lg:text-[18px] font-[400] font-[Montserrat] text-white text-center mt-[25px] lg:mt-[35px] xl:mt-[46px]"
}
export default styles;