const styles = {
    container: "pt-6 pb-16 lg:h-fit background-dynamic ",
    custom_dot: "w-[12px] h-[12px] mx-[6px] rounded-full",
    bg_ellipse: "lg:-mt-[230px] xl:-mt-[220px] mx-auto w-full max-w-[700px] absolute mx-[20%]",
    sections_wrapper: "flex flex-col lg:flex-row items-center lg:items-start mt-44 gap-x-[35px] justify-between border-white h-ful px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px]",
    first_section: "relative flex flex-col items-center lg:items-start w-full",
    heading: "text-[26px] sm:text-[28px] md:text-[30px] lg:text-[35px] xl:text-[38px] font-[700] text-white font-[Montserrat] mt-[57px] text-center lg:text-left max-w-[425px] lg:max-w-[700px]",
    second_section: "flex flex-col max-w-full sm:max-w-[90%] md:max-w-[514px] mt-8 lg:mt-0",
    carousel_wrapper: "my-[6px] h-auto min-h-[310px] lg:min-h-[330px] pt-8",
    star:"h-[19px] lg:h-[22px] w-[19px] lg:w-[22px]",
    quotation: "absolute z-50 -mt-[58px] ml-3",
    card: "bg-white z-40 h-fit rounded-t-[10px] w-full md:max-w-[614px] pt-[25px] sm:pt-[30px] md:pt-[35px] lg:pt-[42px] xl:pt-[47px] px-[15px] pb-8 sm:px-[22px] md:px-[28px] lg:px-[35px] xl:px-[40px]",
    text: "text-[17px] lg:text-[16px] font-[400] font-[Montserrat]",
    name: "text-[16px] font-[600] font-[Montserrat] italic mt-[14px]"
}
export default styles;