import { FC } from "react";
import styles from "./styles";

const Logo: FC = () => {
    return (
        <div className={styles.container}>
            <img src="/assets/logo/Logo.svg" alt='logo' className={styles.logo_icon} />
            <div className='flex flex-col'>
                <p className={styles.jataq}>
                    JATAQ
                </p>
                <p className={styles.technologies}>
                    TECHNOLOGIES
                </p>
            </div>
        </div>
    )
}
export default Logo;