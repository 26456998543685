const styles = {
    container:"fixed lg:hidden w-full md:max-w-[375px] md:w-[467px] h-screen px-[37px] py-[31px] border-[1px] border-[#f5f5f5] right-0 bg-white z-50",
    header:"flex flex-row items-start justify-between",
    close_icon:"bg-[#006fff] cursor-pointer rounded-[10px] h-[39px] w-[46px] p-[8px]",
    tab:"text-[16px] cursor-pointer font-[400] text-black font-[Montserrat] mt-[29px] pb-[7px] w-full",
    tabs:"flex flex-row items-center justify-between cursor-pointer border-[#c4c4c4] border-b-[1px]",
    panel:"absolute py-[10px] px-[20px] shadow-2xl mt-[300px] bg-white border-[1px] rounded-b-[20px] border-[#f5f5f5]",
    services_tab:"flex flex-row my-[10px] gap-x-[20px] cursor-pointer rounded-[20px] px-[17px] py-[8px] bg-white hover:bg-[#edf2ff]",

}
export default styles;