const styles = {
    panel: "absolute ml-[7.8%] py-[10px] px-[12px] shadow-2xl mt-[22px] rounded-b-[20px] bg-white border-[1px] border-[#f5f5f5]",
    service_tab: "flex flex-row my-[10px] gap-x-[20px] cursor-pointer rounded-[20px] px-[17px] py-[8px] bg-white hover:bg-[#edf2ff]",
    container: "w-full px-[18px] sm:px-[60px] md:px-[82px] lg:px-[95px] xl:px-[115px] flex flex-row items-center justify-between py-[18px] max-w-[1590px] mx-auto",
    tabs_wrapper: "w-fit hidden lg:flex flex-row lg:gap-x-[40px] xl:gap-x-[64px]",
    tab: "cursor-pointer text-[#252525] outline-none text-[18px] font-[Montserrat] font-[400]",
    button_wrapper: "flex flex-row gap-x-[12px] md:gap-x-[38px]",
    button: "bg-[#006fff] text-white w-[120px] sm:w-[140px] md:w-[165px] outline-none h-[31px] sm:h-[40px] md:h-[50px] rounded-[10px] md:rounded-[20px] text-[16px] font-[700]",
    menu_icon: "block lg:hidden cursor-pointer h-[33px] w-[33px] sm:w-[40px] sm:h-[40px] md:h-[50px] md:w-[50px]"
}
export default styles;